<template>
  <UiContainer class="!px-0 lg:!px-7">
    <header v-if="!hideHeader" class="px-4 lg:px-0">
      <UiButtonLink
        icon
        tag="RouterLink"
        :to="{ name: 'CompilationView', params: { slug: value.slug } }"
        class="! mb-2 flex w-full items-center justify-between gap-1.5 md:w-fit"
      >
        <UiTitle severity="h3" class="font-semibold">{{ value.title }}</UiTitle>
      </UiButtonLink>
    </header>
    <ul
      :class="[
        'scrollbar-hidden flex gap-3 overflow-y-hidden overflow-x-scroll px-4 lg:px-0',
        {
          'grid-cols-4 lg:grid-cols-5': size === 'small',
          'grid-cols-2 lg:grid-cols-4': size === 'big'
        },
        (!isMobile || showAll) && 'grid gap-6 overflow-x-hidden'
      ]"
    >
      <RouterLink
        v-for="item in items"
        :key="item.object.id"
        :to="getCompilationItemLink(item)"
        custom
        v-slot="{ href, navigate }"
      >
        <li
          class="w-full lg:min-w-px"
          :class="{
            'col-span-1 min-w-2/3 sm:min-w-1/3': size === 'big',
            'col-span-1 min-w-1/2 sm:min-w-1/5': size === 'small'
          }"
        >
          <a :href="href" @click.prevent="navigate()">
            <Component
              :is="compilationComponentsMap[item.type]"
              :value="item.object as Event"
              size="big"
            />
          </a>
        </li>
      </RouterLink>
      <slot v-if="!showAll" name="preview">
        <RouterLink
          v-if="previewItems.length"
          :to="{ name: 'CompilationView', params: { slug: value.slug } }"
          custom
          v-slot="{ href, navigate }"
        >
          <UiSearchResultPreview tag="li" :value="previewItems" :href="href" @click="navigate()" />
        </RouterLink>
      </slot>
    </ul>
    <div class="slider-gradient absolute right-0 top-0 z-10 block h-full w-5 lg:hidden" />
  </UiContainer>
</template>

<script lang="ts" setup>
import { isMobile } from '@basitcodeenv/vue3-device-detect'
import { computed } from 'vue'
import { type Event } from '@/5_entities/Event'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButtonLink, UiContainer, UiSearchResultPreview, UiTitle } from '@/6_shared/ui'
import { getCompilationItemLink, compilationComponentsMap } from '../../lib'
import type { Compilation } from '../../model'

type PropType = {
  value: Compilation
  size?: 'big' | 'small'
  hideHeader?: boolean
  showAll?: boolean
}

const props = withDefaults(defineProps<PropType>(), {
  size: 'big',
  hideHeader: false,
  showAll: false
})
const {
  lg: [isLgAndUp]
} = useBreakpoint()
const MAX_ITEMS_BIG = 4
const MAX_ITEMS_SMALL = 5

const items = computed(() => {
  if (props.showAll) return props.value.items

  return props.value.items.filter((_item, index) =>
    isLgAndUp.value
      ? index < (props.size === 'big' ? MAX_ITEMS_BIG : MAX_ITEMS_SMALL)
      : index < MAX_ITEMS_SMALL
  )
})
const previewItems = computed(() => {
  const startIndex = isLgAndUp.value
    ? props.size === 'big'
      ? MAX_ITEMS_BIG
      : MAX_ITEMS_SMALL
    : MAX_ITEMS_SMALL

  return props.value.items.filter((_item, index) => index >= startIndex && index < startIndex + 3)
})
</script>
